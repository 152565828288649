import * as t from 'io-ts';
import { decode } from './iotsDecoder';

const AccountC = t.type({
    id: t.string,
    name: t.string,
    legal_address: t.type({
        line_1: t.union([t.string, t.undefined, t.null]),
        line_2: t.union([t.string, t.undefined, t.null]),
        line_3: t.union([t.string, t.undefined, t.null]),
        city: t.union([t.string, t.undefined, t.null]),
        postal_code: t.union([t.string, t.undefined, t.null]),
        country_code: t.string,
    }),
    tenant: t.string,
    registration_channel: t.union([t.string, t.undefined, t.null]),
    life_cycle_state: t.union([t.string, t.undefined, t.null]),
    tax_id: t.union([
        t.type({
            value: t.string,
            tax_type: t.string,
        }),
        t.undefined,
        t.null,
    ]),
    account_type: t.union([t.string, t.undefined, t.null]),
    external_identifiers: t.union([
        t.array(
            t.type({
                value: t.string,
                type: t.string,
            }),
        ),
        t.undefined,
        t.null,
    ]),
    _embedded: t.union([
        t.type({
            life_cycle_state_information: t.union([
                t.type({
                    locked_because: t.string,
                    locked_notes: t.union([t.string, t.undefined, t.null]),
                    locked_at: t.union([t.string, t.undefined, t.null]),
                }),
                t.undefined,
                t.null,
            ]),
        }),
        t.undefined,
        t.null,
    ]),
    tenant_specific_data: t.union([
        // latam specific data
        t.type({
            city_id: t.string,
            neighbourhood: t.string,
            phone_number: t.string,
            state: t.string,
            address_details: t.union([t.string, t.undefined, t.null]),
        }),
        t.undefined,
        t.null,
    ]),
    contacts: t.union([
        t.type({
            general: t.type({
                email: t.string,
            }),
            billing: t.union([
                t.type({
                    email: t.string,
                }),
                t.undefined,
                t.null,
            ]),
        }),
        t.undefined,
        t.null,
    ]),
});
export type AccountRest = t.TypeOf<typeof AccountC>;

const ZalandoRestErrorC = t.intersection([
    t.type({
        title: t.string,
        status: t.number,
        detail: t.string,
    }),
    t.partial({
        field_errors: t.record(t.string, t.string),
    }),
]);

export type ZalandoRestError = t.TypeOf<typeof ZalandoRestErrorC>;
export const decodeZalandoRestError = (parsedObject: unknown): ZalandoRestError =>
    decode(parsedObject, ZalandoRestErrorC);

const AccountsApiResponseC = t.type({
    items: t.array(AccountC),
    // _links: t.type({
    //     next: t.union([t.type({ href: t.string }), t.undefined]),
    //     self: t.union([t.type({ href: t.string }), t.undefined]),
    // }),
});

export type AccountsApiResponse = t.TypeOf<typeof AccountsApiResponseC>;

export const decodeAccountsApiResponse = (parsedObject: unknown): AccountsApiResponse =>
    decode(parsedObject, AccountsApiResponseC);

export const decodeAccount = (parsedObject: unknown): AccountRest => decode(parsedObject, AccountC);

export interface AccountInTable {
    id: string;
    name: string;
    tenant: string;
}

export interface LatamSpecificData {
    cityId: string;
    neighbourhood: string;
    phoneNumber: string;
    state: string;
    addressDetails?: string;
}

export const checkIsLatamSpecificData = (obj: unknown): obj is LatamSpecificData =>
    obj !== undefined &&
    obj !== null &&
    typeof obj === 'object' &&
    'cityId' in obj &&
    typeof obj.cityId === 'string' &&
    obj.cityId.length > 0 &&
    'neighbourhood' in obj &&
    'phoneNumber' in obj &&
    'state' in obj;

export const validateLatamSpecificData = (obj: unknown): LatamSpecificData => {
    if (checkIsLatamSpecificData(obj)) {
        return obj;
    }
    throw new Error('unknown tenant specific data');
};

export interface Account {
    id: string;
    name: string;
    tenant: string;
    registrationChannel?: string;
    accountType?: string;
    taxId?: { value: string; taxType: string };
    legalAddress: {
        line1?: string;
        line2?: string;
        line3?: string;
        postCode?: string;
        city?: string;
        countryCode: string;
    };
    lifeCycleState: LifeCycleState;
    lifeCycleStateInformation?: LifeCycleStateInformation;
    remainingExternalIdentifiers?: ExternalIdentifier[];
    dunsNumbers: string[];
    tenantSpecificData?: LatamSpecificData;
    contacts?: Contacts;
}

export type Contacts = {
    general?: {
        email?: string;
    };
    billing?: {
        email?: string;
    };
};

export enum AccountType {
    SUPPLIER = 'SUPPLIER',
    BUYER = 'BUYER',
    CARRIER = 'CARRIER',
    PARTNER_CARRIER = 'PARTNER_CARRIER',
    LOGISTICS_BUYER = 'LOGISTICS_BUYER',
}

export enum LifeCycleState {
    active = 'active',
    locked = 'locked',
    created = 'created',
    unknown = 'unknown',
}

export type LifeCycleStateInformation = {
    lockedBecause: string;
    lockedNotes?: string;
    lockedAt?: string;
};

export type ExternalIdentifier = {
    type: string;
    value: string;
};

const mapToContacts = (
    restContacts:
        | {
              general: {
                  email: string;
              };
              billing:
                  | {
                        email: string;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined,
): Contacts | undefined => {
    if (restContacts === null || restContacts === undefined) {
        return undefined;
    }
    const contacts: Contacts = { general: { email: restContacts.general.email } };

    if (restContacts.billing) {
        contacts.billing = { email: restContacts.billing.email };
    }

    return contacts;
};

export const mapToAccount = (accountRest: AccountRest): Account => {
    return {
        id: accountRest.id,
        name: accountRest.name,
        legalAddress: {
            line1: accountRest.legal_address.line_1 ?? undefined,
            line2: accountRest.legal_address.line_2 ?? undefined,
            line3: accountRest.legal_address.line_3 ?? undefined,
            city: accountRest.legal_address.city ?? undefined,
            postCode: accountRest.legal_address.postal_code ?? undefined,
            countryCode: accountRest.legal_address.country_code ?? undefined,
        },
        tenant: accountRest.tenant,
        registrationChannel: accountRest.registration_channel ?? undefined,
        lifeCycleState: mapToLifeCycleState(accountRest.life_cycle_state) ?? undefined,
        taxId: accountRest.tax_id
            ? { value: accountRest.tax_id.value, taxType: accountRest.tax_id.tax_type }
            : undefined,
        accountType: accountRest.account_type ?? undefined,
        remainingExternalIdentifiers: accountRest.external_identifiers
            ?.filter((el) => el.type !== 'DUNS')
            ?.map((external_identifier: { type: string; value: string }) => ({
                type: external_identifier.type,
                value: external_identifier.value,
            })),
        dunsNumbers: accountRest.external_identifiers?.filter((el) => el.type === 'DUNS')?.map((it) => it.value) ?? [],
        lifeCycleStateInformation: accountRest._embedded?.life_cycle_state_information
            ? {
                  lockedBecause: accountRest._embedded.life_cycle_state_information?.locked_because ?? undefined,
                  lockedNotes: accountRest._embedded.life_cycle_state_information?.locked_notes ?? undefined,
                  lockedAt: accountRest._embedded.life_cycle_state_information?.locked_at ?? undefined,
              }
            : undefined,
        tenantSpecificData: accountRest.tenant_specific_data
            ? {
                  cityId: accountRest.tenant_specific_data.city_id,
                  neighbourhood: accountRest.tenant_specific_data.neighbourhood,
                  phoneNumber: accountRest.tenant_specific_data.phone_number,
                  state: accountRest.tenant_specific_data.state,
                  addressDetails: accountRest.tenant_specific_data.address_details ?? undefined,
              }
            : undefined,
        contacts: mapToContacts(accountRest.contacts),
    };
};

export const mapToLifeCycleState = (value: string | null | undefined): LifeCycleState => {
    if (Object.values(LifeCycleState).includes(value as LifeCycleState)) {
        return value as LifeCycleState;
    }
    return LifeCycleState.unknown;
};

const lockReason = {
    SANCTION_LIST_HIT_UNDER_REVIEW: 'SANCTION_LIST_HIT_UNDER_REVIEW',
    SANCTION_LIST_HIT_BLOCKED_AND_MARKED_FOR_DELETION: 'SANCTION_LIST_HIT_BLOCKED_AND_MARKED_FOR_DELETION',
    INSOLVENCY: 'INSOLVENCY',
    OVERDUE_BILLS: 'OVERDUE_BILLS',
    WAITING_FOR_CUSTOMER_FEEDBACK: 'WAITING_FOR_CUSTOMER_FEEDBACK',
    VAT_ID_INVALID: 'VAT_ID_INVALID',
    FAKE_ACCOUNT: 'FAKE_ACCOUNT',
    OTHER: 'OTHER',
} as const;

export type LockReason = (typeof lockReason)[keyof typeof lockReason];

export const mapToLockReason = (value: string | null | undefined): LockReason => {
    if (Object.keys(lockReason).includes(value as LockReason)) {
        return value as LockReason;
    } else {
        throw new Error(`Unknown lockReason: ${value}`);
    }
};
