import {
    Account,
    ExternalIdentifier,
    LatamSpecificData,
    LifeCycleState,
    LifeCycleStateInformation,
} from '../../../services/schemas/accountsBackendCodec.ts';

export interface FormAccount {
    company: {
        id: string;
        name: string;
        tenant: string;
        registrationChannel?: string;
        accountType?: string;
        taxId?: { value: string; taxType: string };
        legalAddress: {
            line1?: string;
            line2?: string;
            line3?: string;
            postCode?: string;
            city?: string;
            countryCode: string;
        };
        lifeCycleState: LifeCycleState;
        lifeCycleStateInformation?: LifeCycleStateInformation;
        remainingExternalIdentifiers?: ExternalIdentifier[];
        dunsNumbers: string[];
    };
    tenantSpecificData?: LatamSpecificData;
    contacts?: {
        general?: {
            email?: string;
        };
        billing?: {
            email?: string;
        };
    };
}

export const toAccount = (formAccount: FormAccount): Account => {
    return {
        id: formAccount.company.id,
        name: formAccount.company.name,
        tenant: formAccount.company.tenant,
        registrationChannel: formAccount.company.registrationChannel,
        accountType: formAccount.company.accountType,
        taxId: formAccount.company.taxId,
        legalAddress: formAccount.company.legalAddress,
        lifeCycleState: formAccount.company.lifeCycleState,
        lifeCycleStateInformation: formAccount.company.lifeCycleStateInformation,
        remainingExternalIdentifiers: formAccount.company.remainingExternalIdentifiers,
        dunsNumbers: formAccount.company.dunsNumbers,
        tenantSpecificData: formAccount.tenantSpecificData,
        contacts: formAccount.contacts,
    };
};

export const toFormAccount = (account: Account): FormAccount => {
    return {
        company: {
            id: account.id,
            name: account.name,
            tenant: account.tenant,
            registrationChannel: account.registrationChannel,
            accountType: account.accountType,
            taxId: account.taxId,
            legalAddress: account.legalAddress,
            lifeCycleState: account.lifeCycleState,
            lifeCycleStateInformation: account.lifeCycleStateInformation,
            remainingExternalIdentifiers: account.remainingExternalIdentifiers,
            dunsNumbers: account.dunsNumbers,
        },
        tenantSpecificData: account.tenantSpecificData,
        contacts: account.contacts,
    };
};
