import {
    Controller,
    ControllerFieldState,
    ControllerRenderProps,
    LiteralUnion,
    RegisterOptions,
    useFormContext,
} from 'react-hook-form';
import React from 'react';
import { FormAccount } from '../formAccount.ts';

type FieldNames =
    | 'company.id'
    | 'company.name'
    | 'company.tenant'
    | 'company.registrationChannel'
    | 'company.taxId.value'
    | 'company.taxId.taxType'
    | 'company.legalAddress.line1'
    | 'company.legalAddress.line2'
    | 'company.legalAddress.line3'
    | 'company.legalAddress.city'
    | 'company.legalAddress.postCode'
    | 'company.legalAddress.countryCode'
    | 'company.lifeCycleState'
    | 'tenantSpecificData.cityId'
    | 'tenantSpecificData.neighbourhood'
    | 'tenantSpecificData.phoneNumber'
    | 'tenantSpecificData.state'
    | 'tenantSpecificData.addressDetails'
    | 'contacts.general.email'
    | 'contacts.billing.email';

interface GenericFormElementProps {
    description?: string;
    name: FieldNames;
    rules: any;
    renderElement: (
        field: ControllerRenderProps<FormAccount, FieldNames>,
        fieldState: ControllerFieldState,
    ) => React.ReactElement;
}

const GenericFormElement = (props: GenericFormElementProps) => {
    const { control } = useFormContext<FormAccount>(); // retrieve all hook methods

    const errorHint = (errorType: LiteralUnion<keyof RegisterOptions, string>): string => {
        switch (errorType) {
            case 'required':
                return ` is required`;
            case 'pattern':
                return ` has invalid format`;
            case 'maxLength':
                return ` is too Long`;
            default:
                return ' is invalid';
        }
    };

    return (
        <Controller
            name={props.name}
            control={control}
            rules={props.rules}
            render={({ field, fieldState }) => (
                <div className={`form-group ${fieldState.error ? 'has-feedback has-error' : ''}`}>
                    <label className="control-label" htmlFor={props.name}>
                        {props.description}
                    </label>
                    {props.renderElement(field, fieldState)}
                    {fieldState.error && (
                        <>
                            <span className="form-control-feedback rioglyph rioglyph-error-sign" />
                            <span className="help-block">
                                <span>{errorHint(fieldState.error.type)}</span>
                            </span>
                        </>
                    )}
                </div>
            )}
        />
    );
};

export default GenericFormElement;
