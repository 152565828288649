import { Account, AccountType } from '../../../../../services/schemas/accountsBackendCodec';
import React, { useState } from 'react';
import { AddAdminDialog } from '../../dialogs/AddAdminDialog';
import { FleetAdminRequest } from '../../../../../app/types';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { IntlShape, useIntl } from 'react-intl';
import { useInviteAdminMutation } from '../../../../../services/usersApi';
import { useUpdateAccountMutation } from '../../../../../services/accountsApi.ts';

function getButtonText(accountType: string | undefined) {
    switch (accountType) {
        case AccountType.CARRIER:
            return 'Add Fleet Admin';
        case AccountType.BUYER:
            return 'Add Buyer Admin';
        case AccountType.SUPPLIER:
            return 'Add Supplier Admin';
        default:
            return 'Add Fleet Admin';
    }
}

export const AddAdminToAccount = ({ account }: { account: Account }) => {
    const [showDialog, setShowDialog] = useState(false);

    const [inviteFleetAdminRtk] = useInviteAdminMutation();
    const [
        updateAccountRtk,
        // { isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError },
    ] = useUpdateAccountMutation();

    const intl = useIntl();

    const handleClick = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        setShowDialog(true);
    };
    const buttonText = getButtonText(account.accountType);

    const handleAddFltAdminConfirm = async (fleetAdminReq: FleetAdminRequest) => {
        if (
            fleetAdminReq.firstname === undefined ||
            fleetAdminReq.lastname === undefined ||
            fleetAdminReq.email === undefined ||
            fleetAdminReq.reason === undefined
        ) {
            Notification.error(
                'Encountered a problem with the input values. Please make sure to input all required fields.',
            );
            return;
        }

        try {
            if (fleetAdminReq.isGeneralContact || fleetAdminReq.isBillingContact) {
                const accountToUpdate = {
                    ...account,
                    contacts: {
                        general: fleetAdminReq.isGeneralContact
                            ? { email: fleetAdminReq.email }
                            : account.contacts?.general,
                        billing: fleetAdminReq.isBillingContact
                            ? { email: fleetAdminReq.email }
                            : account.contacts?.billing,
                    },
                };
                await updateAccountRtk({
                    account: accountToUpdate,
                    reason: `${fleetAdminReq.reason} - Additionally update account contact information`,
                }).unwrap();
            }
            await inviteFleetAdminRtk({
                user: {
                    firstName: fleetAdminReq.firstname,
                    lastName: fleetAdminReq.lastname,
                    accountId: account.id,
                    accountType: account.accountType,
                    email: fleetAdminReq.email,
                    groupIds: [],
                    phoneNumber: fleetAdminReq.phoneNumber?.replace(/ /g, ''),
                    preferredLanguage: fleetAdminReq.language,
                },
                reason: fleetAdminReq.reason,
            }).unwrap();
            Notification.success(intl.formatMessage({ id: 'helpgang.notification.inviteFleetAdminSuccess' }));
        } catch (error) {
            console.error('Could not invite fleet admin:', error);
            handleError(error, account.id, intl);
        } finally {
            setShowDialog(false);
        }
    };

    return (
        <>
            <button className={'btn btn-primary'} onClick={handleClick}>
                {buttonText}
            </button>
            <AddAdminDialog
                accountName={account.name}
                accountType={account.accountType}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                handleAddAdminConfirm={handleAddFltAdminConfirm}
            />
        </>
    );
};

const handleError = (error: unknown, accountId: string, intl: IntlShape) => {
    if (error !== null && typeof error === 'object' && 'error' in error && typeof error.error === 'string') {
        if (error.error === 'Could not find fleet admin group') {
            Notification.error(`Did not find fleet admin group in account ${accountId}. Please report this issue.`);
        } else if (error.error.startsWith('Error: 409')) {
            if (
                'data' in error &&
                typeof error.data === 'string' &&
                error.data.includes('USERNAME_ALREADY_EXISTS_IN_ACCOUNT')
            ) {
                Notification.error(
                    ` 
                    USERNAME_ALREADY_EXISTS_IN_ACCOUNT 
                    Email or phone number already exists in this account.`,
                    'Error:',
                );
            } else {
                Notification.error(error.error);
            }
        } else {
            Notification.error(intl.formatMessage({ id: 'helpgang.notification.inviteFleetAdminFailure' }));
        }
    } else {
        Notification.error(intl.formatMessage({ id: 'helpgang.notification.inviteFleetAdminFailure' }));
    }
};
