import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import React, { ReactNode, useEffect, useState } from 'react';
import { AccountRest, mapToAccount } from '../../../services/schemas/accountsBackendCodec';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomSearch } from './CustomSearch';
import { accountSelected, resetAccountsSidebar, setShowAccountSidebar } from './sidebar/accountSidebarSlice';
import { getSelectedAccountId, setSelectedAccountId } from './accountsSlice';
import { AccountStatus } from './AccountStatus';
import { config } from '../../../../config';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { useLazyGetAccountsForSearchParamQuery } from '../../../services/accountsApi';
import { ValueWithCopyButton } from '../../common/ValueWithCopyButton';
import { AppDispatch } from '../../../../configuration/setup/store';

const tableClassNames =
    'table table-layout-fixed table-column-overflow-hidden table-bordered table-sticky table-head-filled';

const MAX_ACCOUNTS_TO_DISPLAY = config.maxAccountsToDisplay;

interface AccountsTableProps {
    searchValue: string;
    setSearchValue: (val: string) => void;
}

export const AccountsTable = (props: AccountsTableProps) => {
    const { searchValue, setSearchValue } = props;

    const [selectedRowId, setSelectedRowId] = useState<string | undefined>(undefined);
    const [searchDisabled, setSearchDisabled] = useState(false);

    const [selectedAccount, setSelectedAccount] = useState<AccountRest | undefined>(undefined);
    const [searchChanged, setSearchChanged] = useState(false);

    const intl = useIntl();
    const dispatch = useAppDispatch();

    const selectedAccountIdFromStore = useAppSelector(getSelectedAccountId);

    const [fetchAccountsRtk, { data: accountsNew, isFetching }] = useLazyGetAccountsForSearchParamQuery();

    const handleClickOnRow = (account: AccountRest) => {
        if (selectedAccount && selectedAccount.id === account.id) {
            setSelectedAccount(undefined);
            dispatch(setSelectedAccountId(undefined));
        } else {
            dispatch(setSelectedAccountId(account.id));
            setSelectedAccount(account);
        }
    };

    useEffect(() => {
        if (selectedAccount) {
            openSidebarForAccount(selectedAccount, dispatch);
            setSelectedRowId(selectedAccount.id);
        } else {
            dispatch(resetAccountsSidebar());
            setSelectedRowId(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, dispatch]);

    useEffect(() => {
        if (!isFetching) {
            setSearchDisabled(false);
        }
    }, [isFetching, setSearchDisabled]);

    useEffect(() => {
        if (selectedAccountIdFromStore === undefined) {
            setSelectedAccount(undefined);
            setSelectedRowId(undefined);
        }
    }, [selectedAccountIdFromStore]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchChanged(true);
        setSearchValue(event.currentTarget.value);
    };

    const handleSearch = () => {
        if (!searchChanged) {
            return;
        }
        dispatch(setSelectedAccountId(undefined));
        dispatch(resetAccountsSidebar());

        setSearchDisabled(true);
        setSearchChanged(false);

        fetchAccountsRtk({ search: searchValue });
    };

    const renderTableContent = (): ReactNode => {
        if (isFetching) {
            return (
                <div className={'margin-top-25'}>
                    <table className={tableClassNames}>
                        <thead>
                            <tr>
                                <th>Account Name</th>
                                <th>Account Id</th>
                                <th>Account Tenant</th>
                                <th>Type</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[0, 1, 2].map((it) => (
                                <tr data-cy="accounts-table-row-dummy" key={it}>
                                    <td>
                                        <ContentLoader />
                                    </td>
                                    <td>
                                        <ContentLoader />
                                    </td>
                                    <td>
                                        <ContentLoader />
                                    </td>
                                    <td>
                                        <ContentLoader />
                                    </td>
                                    <td>
                                        <ContentLoader />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        } else if (!isFetching && accountsNew && accountsNew.length > 0) {
            return (
                <div className={'margin-top-25'}>
                    <table className={tableClassNames}>
                        <thead>
                            <tr>
                                <th>Account Name</th>
                                <th>Account Id</th>
                                <th>Account Tenant</th>
                                <th>Type</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {accountsNew.map((rowItem, index) => (
                                <tr
                                    data-cy="accounts-table-row"
                                    key={index}
                                    data-key={rowItem.id}
                                    className={rowItem.id === selectedRowId ? 'active' : ''}
                                    onClick={() => handleClickOnRow(rowItem)}
                                >
                                    <td>{rowItem.name}</td>
                                    <td>
                                        <ValueWithCopyButton value={rowItem.id} />
                                    </td>
                                    <td>{rowItem.tenant}</td>
                                    <td>{rowItem.account_type}</td>
                                    <td>
                                        <AccountStatus status={rowItem.life_cycle_state} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        } else if (!isFetching && accountsNew == undefined) {
            return null;
        } else {
            return (
                <div className={'margin-top-25'}>
                    <NotFoundState
                        headline={intl.formatMessage({ id: 'helpgang.search.nothingFound' })}
                        message={intl.formatMessage({ id: 'helpgang.search.refineSearch' })}
                    />
                </div>
            );
        }
    };

    return (
        <div id={'Accounts'}>
            <div className={'row'}>
                <CustomSearch
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    handleInputChange={handleInputChange}
                    handleSearch={handleSearch}
                    isSearchDisabled={searchDisabled}
                />
            </div>
            {accountsNew && accountsNew.length >= MAX_ACCOUNTS_TO_DISPLAY && <TooManyAccountsWarning />}
            {renderTableContent()}
        </div>
    );
};

const TooManyAccountsWarning = () => (
    <div className={'row'}>
        <div className={'display-flex justify-content-center'}>
            <div
                data-cy={'too-many-results-banner'}
                className={
                    'col-xs-9 col-md-7 col-lg-5 col-xl-3 padding-10 bg-white shadow-smooth rounded display-flex justify-content-center'
                }
            >
                <FormattedMessage
                    id={'helpgang.search.tooManyResults'}
                    values={{ MAX_ACCOUNTS: MAX_ACCOUNTS_TO_DISPLAY }}
                />
            </div>
        </div>
    </div>
);

const openSidebarForAccount = (accountRest: AccountRest, dispatch: AppDispatch) => {
    dispatch(accountSelected(mapToAccount(accountRest)));
    dispatch(setShowAccountSidebar(true));
};
