import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config';
import { prepareHeaders, responseHandler } from './utils';
import {
    Account,
    AccountRest,
    decodeAccount,
    decodeAccountsApiResponse,
    LifeCycleStateInformation,
    validateLatamSpecificData,
} from './schemas/accountsBackendCodec';
import { pipe } from 'fp-ts/function';
import { Intention } from '../components/tabs/accounts/dialogs/AccountLifeCycleChange';

const TagO = {
    ACCOUNT: 'Account',
} as const;

export type Tag = (typeof TagO)[keyof typeof TagO];

const toExternalIdentifiers = (account: Account) => {
    const identifiers = account.remainingExternalIdentifiers ? [...account.remainingExternalIdentifiers] : [];
    account.dunsNumbers.forEach((duns) => {
        if (duns.length > 0) {
            identifiers.push({
                value: duns,
                type: 'DUNS',
            });
        }
    });

    return identifiers;
};

const mapEmptyStringToUndefined = (data: string | undefined) => (data ? data : undefined);

export const mapToRestPutResource = (account: Account) => {
    if (account.tenantSpecificData !== undefined) {
        validateLatamSpecificData(account.tenantSpecificData);
    }
    return {
        id: account.id,
        name: account.name,
        legal_address: {
            line_1: account.legalAddress.line1,
            line_2: account.legalAddress.line2,
            line_3: account.legalAddress.line3,
            city: account.legalAddress.city,
            postal_code: account.legalAddress.postCode,
            country_code: account.legalAddress.countryCode,
        },
        tax_id:
            account.taxId && account.taxId.value && account.taxId.taxType
                ? {
                      value: account.taxId.value,
                      tax_type: account.taxId.taxType,
                  }
                : undefined,
        external_identifiers: toExternalIdentifiers(account),
        tenant: account.tenant,
        registration_channel: account.registrationChannel,
        account_type: account.accountType,
        tenant_specific_data: account.tenantSpecificData
            ? {
                  city_id: account.tenantSpecificData.cityId,
                  neighbourhood: account.tenantSpecificData.neighbourhood,
                  phone_number: account.tenantSpecificData.phoneNumber,
                  state: account.tenantSpecificData.state,
                  address_details: mapEmptyStringToUndefined(account.tenantSpecificData.addressDetails),
              }
            : undefined,
        contacts: account.contacts ? account.contacts : undefined,
    };
};

export const mapToPostResource = (lifeCycleStateInformation: LifeCycleStateInformation) => ({
    locked_because: lifeCycleStateInformation.lockedBecause,
    locked_notes: lifeCycleStateInformation.lockedNotes,
});

export const accountsApi = createApi({
    reducerPath: 'accountsApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.ACCOUNTS_SERVICE, prepareHeaders }),
    tagTypes: [TagO.ACCOUNT],
    endpoints: (build) => ({
        // Queries
        getAccountById: build.query<AccountRest, string>({
            query: (accountId) => ({
                url: `/${accountId}?embed=(life_cycle_state_information)`,
                responseHandler,
            }),
            transformResponse: (rawResult) => decodeAccount(rawResult),
        }),
        getAccountNameById: build.query<string, string>({
            query: (accountId) => ({
                url: `/${accountId}`,
                responseHandler,
            }),
            transformResponse: (rawResult) => pipe(rawResult, decodeAccount, (account) => account.name),
        }),
        getAllAccounts: build.query<AccountRest[], void>({
            query: () => ({
                url: '?embed=(life_cycle_state_information)',
                responseHandler,
            }),
            transformResponse: (rawResult: unknown) => pipe(rawResult, decodeAccountsApiResponse, (it) => it.items),
            providesTags: [TagO.ACCOUNT],
        }),
        getAccountsForSearchParam: build.query<AccountRest[], { search: string }>({
            query: ({ search }) => {
                const encodedSearch = encodeURIComponent(search);
                return {
                    url: `?q=${encodedSearch}&embed=(life_cycle_state_information)`,
                    responseHandler,
                };
            },
            transformResponse: (rawResult: unknown) => pipe(rawResult, decodeAccountsApiResponse, (it) => it.items),
            providesTags: [TagO.ACCOUNT],
        }),

        // Mutations
        deleteAccount: build.mutation<void, { accountId: string; reason: string }>({
            query: ({ accountId, reason }) => ({
                url: `/${accountId}`,
                method: 'DELETE',
                headers: { 'x-rio-audit-reason': reason },
                responseHandler,
            }),
            invalidatesTags: [TagO.ACCOUNT],
        }),
        updateAccount: build.mutation<void, { account: Account; reason: string }>({
            query: ({ account, reason }) => {
                return {
                    url: `/${account.id}`,
                    method: 'PUT',
                    body: mapToRestPutResource(account),
                    headers: { 'x-rio-audit-reason': reason },
                    responseHandler,
                };
            },
            invalidatesTags: [TagO.ACCOUNT],
        }),
        changeLifeCycleState: build.mutation<
            void,
            {
                accountId: string;
                intention: Intention;
                reason: string;
                lifeCycleStateInformation?: LifeCycleStateInformation;
            }
        >({
            query: ({ accountId, intention, reason, lifeCycleStateInformation }) => ({
                url: `/${accountId}/${intention}`,
                method: 'POST',
                body: intention === Intention.lock ? mapToPostResource(lifeCycleStateInformation!) : undefined,
                headers: { 'x-rio-audit-reason': reason },
                responseHandler,
            }),
            invalidatesTags: [TagO.ACCOUNT],
        }),
    }),
});

export const {
    useLazyGetAccountByIdQuery,
    useLazyGetAllAccountsQuery,
    useDeleteAccountMutation,
    useChangeLifeCycleStateMutation,
    useUpdateAccountMutation,
    useLazyGetAccountNameByIdQuery,
    useLazyGetAccountsForSearchParamQuery,
} = accountsApi;
