import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import GenericFormElement from './GenericFormElement.tsx';

const SimpleTextFormElement = (props: {
    description?: string;
    name:
        | 'company.id'
        | 'company.name'
        | 'company.tenant'
        | 'company.registrationChannel'
        | 'company.taxId.value'
        | 'company.taxId.taxType'
        | 'company.legalAddress.line1'
        | 'company.legalAddress.line2'
        | 'company.legalAddress.line3'
        | 'company.legalAddress.city'
        | 'company.legalAddress.postCode'
        | 'company.legalAddress.countryCode'
        | 'company.lifeCycleState'
        | 'tenantSpecificData.cityId'
        | 'tenantSpecificData.neighbourhood'
        | 'tenantSpecificData.phoneNumber'
        | 'tenantSpecificData.state'
        | 'tenantSpecificData.addressDetails'
        | 'contacts.general.email'
        | 'contacts.billing.email';
    rules: any;
    isEditable: boolean;
}) => {
    return (
        <GenericFormElement
            description={props.description}
            name={props.name}
            rules={props.rules}
            renderElement={(field, _fieldState) => {
                if (props.isEditable) {
                    return <ClearableInput {...field} id={props.name} data-testid={props.name} />;
                } else {
                    return <input className={'form-control'} {...field} id={props.name} disabled />;
                }
            }}
        />
    );
};

export default SimpleTextFormElement;
